import { gql } from '@apollo/client';
import { EmployeeStatus } from '@jbc-year-end-adj/2024/types';

export type QueryResult = {
  client: {
    id: string;
    clientYearly: {
      id: string;
      employee: {
        id: string;
        status: EmployeeStatus;
        withholdingSlip: WithholdingSlip;
        profile: {
          id: string;
          formerJobs: FormerJob[];
        };
      };
    };
  };
};

export type WithholdingSlip = {
  id: string;
  summary: string;
  remark: string;
  autoSummary: string;
  editedAutoSummary: {
    id: string;
    body: string;
  };
};

export type FormerJob = {
  id: string;
  companyName: string;
  address?: string;
  resignedOn: string;
  paymentAmount?: string;
  taxAmount?: string;
  deductionAmount?: string;
};

export const WITHHOLDING_SLIP_SUMMARY = gql`
  query withholdingSlipSummary($id: ID!) {
    client {
      id
      clientYearly {
        id
        employee(id: $id) {
          id
          status
          withholdingSlip {
            id
            summary
            remark
            autoSummary
            editedAutoSummary {
              id
              body
            }
          }
          profile {
            id
            formerJobs {
              id
              companyName
              address
              resignedOn
              paymentAmount
              taxAmount
              deductionAmount
            }
          }
        }
      }
    }
  }
`;
