import { gql } from '@apollo/client';
import { Schema } from './schema';

export const convertToVariables = (data: Schema, employeeId: string) => {
  return {
    input: {
      employeeId,
      attributes: {
        autoSummary: data.autoSummary,
        summary: data.summary,
        remark: data.remark
      }
    }
  };
};

export const SAVE_WITHHOLDING_SLIP_SUMMARY = gql`
  mutation saveWithholdingSlipSummary($input: SaveWithholdingSlipSummaryInput!) {
    saveWithholdingSlipSummary(input: $input) {
      employee {
        id
        withholdingSlip {
          id
        }
      }
    }
  }
`;
