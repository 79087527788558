import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { FormSection as FormSectionOrig, Field, change } from 'redux-form';
import classnames from 'classnames';
import { Edditing } from 'jbc-front/components/icons';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { requiredIf } from 'jbc-front/utils/validators';

import { amount, amountAllowMinus } from '@jbc-year-end-adj/ancient/validators';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useFormValue, useAutofill } from '@jbc-year-end-adj/2024/features/FormName/FormNameProvider';
import { parseNumber, parseNumbers } from '@jbc-year-end-adj/2024/utils/income';

import styles from '../WithholdingSlip.scss';

// @ts-ignore
import recordDisplay, { numberWithSplit } from '@jbc-year-end-adj/2024/utils/recordDisplay';

const maxLengthAmount = length => (value, previousValue) => (value.replace(/,/g, '').length > length ? previousValue : value);

export const FormSection = ({ name, label, children }) => (
  <>
    <LabelMapper name={name} label={label} />
    <FormSectionOrig name={name}>{children}</FormSectionOrig>
  </>
);

export const Table = ({ children }) => <table className={styles.tableLayout}>{children}</table>;

export const Tr = ({ bold, readOnly, children }) => (
  <tr className={classnames({ [styles.bgGray]: readOnly, [styles.bold]: bold })}>{children}</tr>
);

export const Th = ({ width = 400, right, children }) => (
  <th className={classnames([styles.th], { 'u-ta-r': right })} width={width}>
    {children}
  </th>
);

export const Td = ({ children }) => <td className="u-ta-r">{children}</td>;

export const Note = ({ children }) => <p className={styles.note}>{children}</p>;

export const Section = ({ formName, title, button, padding, children }) => {
  const Component = formName ? FormSection : React.Fragment;
  const componentProps = formName ? { name: formName, label: `${title}の` } : {};
  return (
    <Component {...componentProps}>
      <section>
        {button && <div className={styles.button}>{button}</div>}
        <div className={styles.panel}>
          {title && <div className={styles.panelTitle}>{title}</div>}
          <div className={classnames({ [styles.panelPadding]: padding, [styles.noHeader]: !title })}>{children}</div>
        </div>
      </section>
    </Component>
  );
};

export const InputField = ({ name, label, required, ...rest }) => (
  <>
    <LabelMapper name={name} label={label} />
    <Field
      validate={[amountAllowMinus, requiredIf(required)]}
      name={name}
      component={renderNumberField}
      normalize={maxLengthAmount(10)}
      {...rest}
    />
  </>
);

const renderNumberField = ({ input, meta: { dispatch, form, touched, error, submitFailed }, edited, readOnly }) => (
  <div className={classnames([styles.field], { [styles.error]: touched && error && submitFailed })}>
    {edited && (
      <span className={styles.icon}>
        <Edditing size={14} />
      </span>
    )}
    <input
      {...input}
      type="text"
      className={styles.inputNumber}
      readOnly={readOnly}
      onBlur={e => {
        dispatch(change(form, e.currentTarget.name, amountFormat(input.value)));
      }}
    />
    <span className={styles.yen}>円</span>
  </div>
);

export const Total = ({ formValues = [], handleCalc, name }) => {
  const [total, setTotal] = useState();
  const { autofill } = useAutofill();
  useEffect(() => {
    const nums = parseNumbers(...formValues);
    const value = nums.some(_.isNaN) ? '-' : handleCalc(nums);
    if (name) {
      autofill(`totalAmount.${name}`, `${value}`);
    }
    setTotal(value);
  }, [formValues, handleCalc, autofill, name]);
  return (
    <div className={styles.totalField}>
      <div className={styles.total}>{recordDisplay(numberWithSplit(total))}</div>
      <span className={styles.yen}>円</span>
    </div>
  );
};

export const ManualField = ({ name, label, autoSum, ...rest }) => {
  const field = `edited.${name}`;
  const edited = useFormValue(field);
  const { autofill } = useAutofill();
  const onBlur = useCallback(
    (_, v) => {
      autofill(field, autoSum !== parseNumber(v));
    },
    [field, autofill, autoSum]
  );
  return (
    <>
      <LabelMapper name={name} label={label} />
      <Field
        validate={amount}
        name={name}
        onBlur={onBlur}
        component={renderNumberField}
        edited={edited}
        normalize={maxLengthAmount(10)}
        {...rest}
      />
    </>
  );
};
