import { gql } from '@apollo/client';
import { ASYNC_TASK_FRAGMENT } from '@jbc-year-end-adj/2024/features/AsyncTask/query';

export const IMPORT = gql`
  mutation importWithholdingSlipsCsv($input: ImportWithholdingSlipsCsvInput!) {
    importWithholdingSlipsCsv(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;
