import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import classnames from 'classnames';
import Button from 'jbc-front/components/Button';
import { Spacer } from 'jbc-front/components/presenters/Spacer';
import { EditSquare } from 'jbc-front/components/icons';

import { parseBackUrl } from '@jbc-year-end-adj/2024/utils/url';
import { amountFormat } from '@jbc-year-end-adj/2024/utils/formatter';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { Textarea } from '@jbc-year-end-adj/2024/components/form/Textarea';
import { Label } from '@jbc-year-end-adj/2024/components/form/Label';
import { FormField } from '@jbc-year-end-adj/2024/components/form/FormField';
import RollbackActionButton from 'components/RollbackActionButton';
import { schema, Schema } from './schema';
import { convertToVariables, SAVE_WITHHOLDING_SLIP_SUMMARY } from './mutation';
import { FormerJob, WithholdingSlip } from '../../query';
import styles from './Form.scss';

// @ts-ignore
import { Section } from '@jbc-year-end-adj/2024/adminPages/result/withholdingSlip/edit/parts/parts';

const makeSummaryFormerJobs = (formerJobs: FormerJob[]) =>
  formerJobs.map(formerJob =>
    [
      `前職 会社名 ${formerJob.companyName}`,
      `住所 ${formerJob.address ?? ''}`,
      `退職日 ${formerJob.resignedOn}`,
      `支払金額 ${amountFormat(formerJob.paymentAmount)}円`,
      `源泉徴収税額 ${amountFormat(formerJob.taxAmount)}円`,
      `社会保険料等の金額 ${amountFormat(formerJob.deductionAmount)}円`
    ].join('　')
  );

type FormProps = {
  employeeId: string;
  formerJobs: FormerJob[];
  withholdingSlip: WithholdingSlip;
};

type SummaryFormerJobsProps = {
  summaryFormerJobs: string[];
};

const SummaryFormerJobs: FC<SummaryFormerJobsProps> = ({ summaryFormerJobs }) => (
  <FormField>
    <FormField.LabelContainer>
      <Label>前職情報</Label>
    </FormField.LabelContainer>

    {summaryFormerJobs.map((summaryFormerJob, index) => (
      <div className={styles.summaryFormerJob} key={index}>
        <div className={styles.formerJobLabel}>{`前職${index + 1}`}</div>
        <div className={styles.formerJobContent}>{summaryFormerJob}</div>
      </div>
    ))}
  </FormField>
);

type SummaryCounterProps = {
  summary: string;
  autoSummary: string;
  summaryFormerJobs: string[];
};

const SummaryCounter: FC<SummaryCounterProps> = ({ summary, autoSummary, summaryFormerJobs }) => {
  let summaryLength = [...summary, ...autoSummary, ...summaryFormerJobs.join('')].length;
  if (autoSummary && summaryFormerJobs.length > 0) {
    summaryLength += summaryFormerJobs.length;
  }
  if ((autoSummary || summaryFormerJobs.length > 0) && summary) {
    summaryLength += 1;
  }
  return <div className={classnames('u-ta-r', { [styles.alert]: summaryLength > 300 })}>使用文字数： {summaryLength}字</div>;
};

export const Form: FC<FormProps> = ({ employeeId, withholdingSlip, formerJobs = [] }) => {
  const [saveWithholdingSlipSummary] = useMutation(SAVE_WITHHOLDING_SLIP_SUMMARY);
  const { history, location } = useReactRouter();
  const editedAutoSummary = withholdingSlip?.editedAutoSummary;
  const autoSummary = withholdingSlip?.autoSummary || '';
  const summaryFormerJobs = makeSummaryFormerJobs(formerJobs);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { isSubmitting }
  } = useForm<Schema>({
    defaultValues: {
      autoSummary: editedAutoSummary ? editedAutoSummary.body : autoSummary,
      summary: withholdingSlip.summary,
      remark: withholdingSlip.remark
    },
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const autoSummaryInput = watch('autoSummary') || '';
  const summaryInput = watch('summary') || '';

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    await saveWithholdingSlipSummary({ variables: convertToVariables(data, String(employeeId)) });
    history.push(parseBackUrl(location, '/result'));
  };

  const handleRollback = async () => {
    setValue('autoSummary', autoSummary);
    setValue('summary', '');
  };
  const isEditedAutoSummary = autoSummaryInput !== autoSummary;

  return (
    <div className={styles.sectionWrap}>
      <Section title="源泉徴収票の摘要欄" padding>
        <div className="u-ta-r u-mb5">
          <RollbackActionButton onClick={handleRollback} />
        </div>

        <FormField>
          <div className={styles.labelContainer}>
            <Label>自動入力欄</Label>
            {isEditedAutoSummary && <EditSquare className={styles.icon} />}
          </div>
          <Textarea {...register('autoSummary')} name="autoSummary" />
        </FormField>
        <Spacer direction="y" size={20} />
        {summaryFormerJobs.length > 0 && <SummaryFormerJobs summaryFormerJobs={summaryFormerJobs} />}
        <FormField>
          <div className={styles.labelContainer}>
            <Label>任意入力欄</Label>
            {summaryInput && <EditSquare className={styles.icon} />}
          </div>
          <Textarea {...register('summary')} name="summary" />
        </FormField>

        <div className="u-mt-10">
          <SummaryCounter autoSummary={autoSummaryInput} summary={summaryInput} summaryFormerJobs={summaryFormerJobs} />
        </div>

        <div className="u-mt-10">
          <span className={styles.alert}>【注意】</span>
          <br />
          摘要欄の文字数制限は300文字です。
          <br />
          自動入力欄、前職情報、任意入力欄の文字数が301文字以上となった場合、摘要欄に表示される情報は300文字までのものとなります。
        </div>
      </Section>

      <Section title="源泉徴収票の備考欄" padding>
        <Textarea {...register('remark')} name="remark" label="備考" />
      </Section>

      <div className="u-ta-c u-mt30">
        <Button onClick={handleSubmit(onSubmit)} primary disabled={isSubmitting}>
          保存
        </Button>
      </div>
    </div>
  );
};
