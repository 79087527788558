import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { usePrefectureOptions } from 'hooks/usePrefectureOptions';
import { suppressFetchAddressError, useFetchAddress } from 'hooks/useAddress';
import { useYear } from 'hooks/useYear';

import { PostcodeInput } from 'components/form/PostcodeInput';
import { SelectField } from 'components/react-hook-form/SelectField';
import { Input } from 'components/form/Input';
import { Schema } from '../schema';

import { Grid } from '../../../../../components/Grid';

type DifferentAddressType = {
  index: number;
};

export const DifferentAddress: FC<DifferentAddressType> = ({ index }) => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
    trigger
  } = useFormContext<Schema>();

  const { prefectureOptions } = usePrefectureOptions();
  const fetchAddress = useFetchAddress();
  const year = useYear();

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const postcode0 = watch(`otherFamilies.${index}.nextYear.yearlyInfo.postcode0`);
  const postcode1 = watch(`otherFamilies.${index}.nextYear.yearlyInfo.postcode1`);
  const prefectureId = watch(`otherFamilies.${index}.nextYear.yearlyInfo.prefectureId`);

  useEffect(() => {
    if (!isFirstRender) {
      if (postcode0 && postcode1) {
        let cancelled = false;
        fetchAddress(`${postcode0}${postcode1}`, year)
          .catch(suppressFetchAddressError)
          .then(address => {
            if (cancelled || !address) return;

            const prefecture = prefectureOptions.find(option => option.label === address.prefecture);
            setValue(`otherFamilies.${index}.nextYear.yearlyInfo.prefectureId`, prefecture?.value);
            setValue(`otherFamilies.${index}.nextYear.yearlyInfo.city`, address.city);
            setValue(`otherFamilies.${index}.nextYear.yearlyInfo.street`, address.street);

            trigger([
              `otherFamilies.${index}.nextYear.yearlyInfo.prefectureId`,
              `otherFamilies.${index}.nextYear.yearlyInfo.city`,
              `otherFamilies.${index}.nextYear.yearlyInfo.street`
            ]);
          });
        return () => {
          cancelled = true;
        };
      }
    } else {
      setIsFirstRender(false);
    }
    return;
  }, [postcode0, postcode1]);

  const otherFamilyErrors = errors.otherFamilies?.[index];

  const postcodeErrors = [
    otherFamilyErrors?.nextYear?.yearlyInfo?.postcode0?.message,
    otherFamilyErrors?.nextYear?.yearlyInfo?.postcode1?.message
  ].reduce<string[]>((errors, message) => {
    if (message) {
      errors.push(message);
    }

    return errors;
  }, []);

  return (
    <Grid colGap>
      <PostcodeInput
        required
        errors={postcodeErrors}
        firstCodeProps={{
          isError: !!otherFamilyErrors?.nextYear?.yearlyInfo?.postcode0?.message,
          ...register(`otherFamilies.${index}.nextYear.yearlyInfo.postcode0`)
        }}
        lastCodeProps={{
          isError: !!otherFamilyErrors?.nextYear?.yearlyInfo?.postcode1?.message,
          ...register(`otherFamilies.${index}.nextYear.yearlyInfo.postcode1`)
        }}
      />
      <SelectField
        label="都道府県"
        options={prefectureOptions}
        value={prefectureOptions.find(option => option.value === prefectureId)}
        required
        name={`otherFamilies.${index}.nextYear.yearlyInfo.prefectureId`}
        error={otherFamilyErrors?.nextYear?.yearlyInfo?.prefectureId?.message}
      />
      <Input
        label="市区町村"
        required
        error={otherFamilyErrors?.nextYear?.yearlyInfo?.city?.message}
        isError={!!otherFamilyErrors?.nextYear?.yearlyInfo?.city?.message}
        {...register(`otherFamilies.${index}.nextYear.yearlyInfo.city`)}
      />
      <Input
        label="丁目番地号"
        error={otherFamilyErrors?.nextYear?.yearlyInfo?.street?.message}
        isError={!!otherFamilyErrors?.nextYear?.yearlyInfo?.street?.message}
        {...register(`otherFamilies.${index}.nextYear.yearlyInfo.street`)}
      />
      <Input
        label="建物名"
        error={otherFamilyErrors?.nextYear?.yearlyInfo?.building?.message}
        isError={!!otherFamilyErrors?.nextYear?.yearlyInfo?.building?.message}
        {...register(`otherFamilies.${index}.nextYear.yearlyInfo.building`)}
      />
    </Grid>
  );
};
