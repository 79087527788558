import { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';
import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';
import { Note } from 'jbc-front/components/presenters/ui/Note';

import { FormSection } from 'components/form/FormSection';
import { DateField } from 'components/react-hook-form/DateField';
import { Input } from 'components/form/Input';
import { Radio } from 'components/form/Radio';
import { Option } from 'components/form/SelectField';
import { PostcodeInput } from 'components/form/PostcodeInput';
import { CreatableSelectField } from 'components/react-hook-form/CreatableSelectField';
import { SelectField } from 'components/react-hook-form/SelectField';
import { FormField } from 'components/form/FormField';
import { usePrefectureOptions } from 'hooks/usePrefectureOptions';
import { suppressFetchAddressError, useFetchAddress } from 'hooks/useAddress';
import { useYear } from 'hooks/useYear';
import { Schema, schema, generateDefaultValues } from './schema';
import { UPDATE_BASIC_INFOS, convertFormDataToQueryVariables } from './mutation';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { Grid } from '../../../../components/Grid';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';
import { RELATIONSHIPS } from '../../../../consts';
import { FETCH_EMPLOYEE } from '../../../../query';

import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type FormProps = {
  onCancel: () => void;
  employmentTypeOptions: Option[];
  positionOptions: Option[];
};

export const Form: FC<FormProps> = ({ onCancel, employmentTypeOptions, positionOptions }) => {
  const { prefectureOptions } = usePrefectureOptions();
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;
  const fetchAddress = useFetchAddress();
  const year = useYear();
  const [update, { loading }] = useMutation(UPDATE_BASIC_INFOS, convertFormDataToQueryVariables);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(employee),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger
  } = methods;

  const onSubmit: SubmitHandler<Schema> = async data => {
    const variables = convertFormDataToQueryVariables(String(employee.id), data);

    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('基本情報を保存しました。', 'success');
  };

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const residentStatus = watch('isNonResident');
  const postcode0 = watch('postcode0');
  const postcode1 = watch('postcode1');

  useEffect(() => {
    if (!isFirstRender) {
      if (postcode0 && postcode1) {
        let cancelled = false;
        fetchAddress(`${postcode0}${postcode1}`, year)
          .catch(suppressFetchAddressError)
          .then(address => {
            if (cancelled || !address) return;

            const prefecture = prefectureOptions.find(option => option.label === address.prefecture);
            if (prefecture) {
              setValue('prefectureId', prefecture?.value);
            }
            setValue('city', address.city);
            setValue('street', address.street);

            trigger(['prefectureId', 'city', 'street']);
          });
        return () => {
          cancelled = true;
        };
      }
    } else {
      setIsFirstRender(false);
    }
    return;
  }, [postcode0, postcode1]);

  const postcodeErrors = [errors?.postcode0?.message, errors?.postcode1?.message].reduce<string[]>((errors, message) => {
    if (message) {
      errors.push(message);
    }

    return errors;
  }, []);

  const householderRelationship = watch('householderRelationship');

  const relationshipOptions: Option[] = [
    { value: '', label: '' },
    ...RELATIONSHIPS.map(relationship => ({ value: relationship, label: relationship }))
  ];
  const relationship = profile.householder?.relationship;
  if (relationship && !RELATIONSHIPS.includes(relationship)) {
    relationshipOptions.push({ label: relationship, value: relationship });
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <Grid colGap>
            <DateField label="入社日" required note="例）1981/01/23、S56.1.23" error={errors.joinedOn?.message} name="joinedOn" />

            <DateField label="退職日" error={errors.resignedOn?.message} note="例）1981/01/23、S56.1.23" name="resignedOn" />
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <Input label="姓" required error={errors.lastName?.message} isError={!!errors.lastName?.message} {...register('lastName')} />
            <Input label="名" required error={errors.firstName?.message} isError={!!errors.firstName?.message} {...register('firstName')} />
          </Grid>

          <Grid colGap>
            <Input
              label="姓(カナ)"
              required
              error={errors.lastNameKana?.message}
              isError={!!errors.lastNameKana?.message}
              {...register('lastNameKana')}
            />
            <Input
              label="名(カナ)"
              required
              error={errors.firstNameKana?.message}
              isError={!!errors.firstNameKana?.message}
              {...register('firstNameKana')}
            />
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <DateField label="生年月日" required note="例）1981/01/23、S56.1.23" error={errors.birthday?.message} name="birthday" />

            <Radio.Container label="性別" required>
              <Radio value="male" {...register('gender')}>
                男性
              </Radio>
              <Radio value="female" {...register('gender')}>
                女性
              </Radio>
            </Radio.Container>
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <CreatableSelectField
              label="雇用形態"
              error={errors.employmentType?.message}
              options={employmentTypeOptions}
              value={employmentTypeOptions.find(option => option.value === employee?.employmentType)}
              name="employmentType"
              note="※こちらで変更した「雇用形態」は労務HR、給与計算の従業員情報には自動で反映されませんのでそれぞれのサービスでも変更が必要です。"
              noteMaxLength={19}
            />

            <CreatableSelectField
              label="役職"
              error={errors.position?.message}
              options={positionOptions}
              value={positionOptions.find(option => option.value === employee?.position)}
              name="position"
              note="※こちらで変更した「役職」は労務HR、給与計算の従業員情報には自動で反映されませんのでそれぞれのサービスでも変更が必要です。"
              noteMaxLength={19}
            />
          </Grid>

          <FormSeparator />

          <Grid>
            <FormField>
              <Radio.Container label="非居住者区分" required>
                <Radio value="resident" {...register('isNonResident')}>
                  対象外
                </Radio>
                <Radio value="nonResident" {...register('isNonResident')}>
                  非居住者
                </Radio>
              </Radio.Container>
              <FormField.NoteContainer>
                <Note>
                  <ReadMoreReadLess maxLength={19}>
                    一般的に国内に住所を有する場合は対象外（居住者）、国外に住所を有する場合は非居住者に区分します。 詳細は
                    <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/gensen/2875.htm" rel="noopener noreferrer" target="_blank">
                      国税庁のページ
                    </a>
                    をご確認ください。
                  </ReadMoreReadLess>
                </Note>
              </FormField.NoteContainer>
            </FormField>
          </Grid>

          {residentStatus === 'resident' && (
            <Grid colGap>
              <PostcodeInput
                required
                errors={postcodeErrors}
                firstCodeProps={{
                  isError: !!errors.postcode0?.message,
                  ...register('postcode0')
                }}
                lastCodeProps={{
                  isError: !!errors.postcode1?.message,
                  ...register('postcode1')
                }}
              />

              <SelectField
                label="都道府県"
                options={prefectureOptions}
                value={prefectureOptions.find(option => option.value === profile?.prefectureId)}
                required
                name="prefectureId"
                error={errors.prefectureId?.message}
              />
              <Input label="市区町村" required error={errors.city?.message} isError={!!errors.city?.message} {...register('city')} />
              <Input label="丁目番地号" error={errors.street?.message} isError={!!errors.street?.message} {...register('street')} />
              <Input label="建物名" error={errors.building?.message} isError={!!errors.building?.message} {...register('building')} />
            </Grid>
          )}

          <FormSeparator />

          <Grid colGap>
            <CreatableSelectField
              label="世帯主の続柄"
              note="一人暮らしの場合の世帯主は「本人」です。"
              options={relationshipOptions}
              error={errors.householderRelationship?.message}
              name="householderRelationship"
              value={relationshipOptions.find(option => option.value === relationship)}
            />

            {householderRelationship && householderRelationship !== '本人' && (
              <Input
                label="世帯主の氏名"
                error={errors.householderName?.message}
                isError={!!errors.householderName?.message}
                {...register('householderName')}
              />
            )}
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <Radio.Container label="外国人区分" required>
              <Radio value="japanese" {...register('nationalType')}>
                日本人
              </Radio>
              <Radio value="foreigner" {...register('nationalType')}>
                外国人
              </Radio>
            </Radio.Container>
          </Grid>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
