import React from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';
import useReactRouter from 'use-react-router';

import FormErrors, { onSubmitFail } from 'jbc-front/components/FormErrors';
import Button from 'jbc-front/components/Button';

import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { year } from '@jbc-year-end-adj/2024/consts';
import { reduxForm } from '@jbc-year-end-adj/2024/features/FormName/FormNameProvider';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { toFormValues } from '@jbc-year-end-adj/2024/utils/form';
import { LoadingPage } from '@jbc-year-end-adj/2024/components/ui/LoadingPage';
import PaymentDeduction from './parts/paymentDeduction';
import InsuranceDeduction, { INSURANCE_AMOUNT_SUM_FRAGMENT } from './parts/insuranceDeduction';

import styles from './WithholdingSlip.scss';

const WITHHOLDING_SLIP_FRAGMENT = gql`
  fragment WithholdingSlip on WithholdingSlip {
    payPaymentAmount
    payDeductionAmount
    payIncomeTax
    bonusPaymentAmount
    bonusDeductionAmount
    bonusIncomeTax
    formerJobsPaymentAmount
    formerJobsDeductionAmount
    formerJobsTaxAmount
    adjustmentPaymentAmount
    adjustmentDeductionAmount
    adjustmentIncomeTax
    smallMutualAidPremium
  }
`;

const WITHHOLDING_SLIP = gql`
  query withholdingSlip($id: ID!) {
    client {
      id
      clientYearly {
        id
        employee(id: $id) {
          id
          staffCode
          status
          insuranceAmountAutoSum {
            ...InsuranceAmountSum
          }
          insuranceAmountFinalSum {
            ...InsuranceAmountSum
          }
          withholdingSlip {
            id
            ...WithholdingSlip
          }
          profile {
            id
            firstName
            lastName
            formerJobsTaxAmount
            formerJobsPaymentAmount
            formerJobsDeductionAmount
            formerJobs {
              id
            }
          }
        }
      }
    }
  }
  ${WITHHOLDING_SLIP_FRAGMENT}
  ${INSURANCE_AMOUNT_SUM_FRAGMENT}
`;

const SAVE_WITHHOLDING_SLIP = gql`
  mutation saveWithholdingSlip($input: SaveWithholdingSlipInput!) {
    saveWithholdingSlip(input: $input) {
      employee {
        id
        insuranceAmountFinalSum {
          ...InsuranceAmountSum
        }
        withholdingSlip {
          id
          ...WithholdingSlip
        }
      }
    }
  }
  ${WITHHOLDING_SLIP_FRAGMENT}
  ${INSURANCE_AMOUNT_SUM_FRAGMENT}
`;

const initialValues = profile => {
  return {
    payPaymentAmount: '0',
    payDeductionAmount: '0',
    payIncomeTax: '0',
    bonusPaymentAmount: '0',
    bonusDeductionAmount: '0',
    bonusIncomeTax: '0',
    smallMutualAidPremium: '0',
    formerJobsPaymentAmount: profile.formerJobsPaymentAmount,
    formerJobsDeductionAmount: profile.formerJobsDeductionAmount,
    formerJobsTaxAmount: profile.formerJobsTaxAmount,
    adjustmentPaymentAmount: '0',
    adjustmentDeductionAmount: '0',
    adjustmentIncomeTax: '0'
  };
};

const makeInitialValues = (withholdingSlip, employee, profile) => {
  const { ...rest } = withholdingSlip || {};
  const { insuranceAmountFinalSum, insuranceAmountAutoSum } = employee;
  const insuranceAmountSum = _.mapValues(toFormValues(insuranceAmountFinalSum, true), amountFormat);
  const { housingLoanDeductionApplicableAmount } = insuranceAmountSum;
  const variables = Object.keys(rest).length === 0 ? initialValues(profile) : rest;
  return {
    paymentDeduction: _.mapValues(toFormValues(variables, true), amountFormat),
    insuranceDeduction: _.omit(insuranceAmountSum, 'housingLoanDeductionApplicableAmount'),
    housingLoanDeduction: { housingLoanDeductionApplicableAmount },
    edited: _.fromPairs(_.map(insuranceAmountFinalSum, (v, k) => [k, insuranceAmountAutoSum[k] !== v])),
    totalAmount: {
      deductionAmount: '0',
      generalLifeInsurance: '0',
      nursingMedicalInsurance: '0',
      individualAnnuityInsurance: '0'
    }
  };
};

const Form =
  (({ submitting, handleSubmit, employee }) => {
    return (
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className="l-title-wrap">
            <h1 className="m-title-main">源泉徴収票</h1>
            <div className={styles.employeeInfo}>
              <p>
                スタッフコード：{employee.staffCode}　氏名：{employee.profile.lastName + ' ' + employee.profile.firstName}
              </p>
            </div>
          </div>
          <div className={styles.formErrorsWrap}>
            <FormErrors />
          </div>
          <div className={styles.sectionWrap}>
            <PaymentDeduction />
            {employee.status !== 'not_need_adj' && <InsuranceDeduction />}
          </div>
          <div className="u-ta-c u-mt30">
            <Button onClick={handleSubmit} primary disabled={submitting}>
              次へ
            </Button>
          </div>
        </form>
      </div>
    );
  }) |> reduxForm({ form: 'withholdingSlip', onSubmitFail });

export const WithholdingSlip = () => {
  const {
    match: {
      params: { id }
    },
    history,
    location: { search }
  } = useReactRouter();
  const { data, loading } = useQuery(WITHHOLDING_SLIP, {
    variables: { id },
    fetchPolicy: 'network-only'
  });
  const [saveWithholdingSlip] = useMutation(SAVE_WITHHOLDING_SLIP);
  if (loading) return <LoadingPage />;
  const {
    client: {
      clientYearly: {
        employee,
        employee: { withholdingSlip, profile }
      }
    }
  } = data;

  return (
    <Form
      initialValues={makeInitialValues(withholdingSlip, employee, profile)}
      onSubmit={async values => {
        const input = _.omit(values, ['edited', 'totalAmount']);
        await saveWithholdingSlip({ variables: { input: { employeeId: id, attributes: input } } });
        history.push(`/${year}/employee/${id}/withholding_slip_summary${search}`);
      }}
      employee={employee}
    />
  );
};
