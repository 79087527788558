import { yup } from '@jbc-year-end-adj/common/yup';
import { FileType } from '@jbc-year-end-adj/types';
import { FormerJob } from '../../../../query';
import { dateFormFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

export type Schema = {
  formerJobs: FormerJobSchema[];
};

type WithholdingSlipStatusType = 'submitted' | 'not_submitted' | 'unavailable' | 'in_order';

export type FormerJobSchema = {
  id?: string;
  companyName: string;
  address?: string;
  resignedOn: string;
  lastSalaryOn?: string;
  withholdingSlipStatus: WithholdingSlipStatusType | null;
  withholdingSlipImage: File | FileType | null;
  deductionAmount?: string;
  paymentAmount?: string;
  taxAmount?: string;
};

const formerJobSchema = yup.object({
  id: yup.string(),
  companyName: yup
    .string()
    .required()
    .label('会社名'),
  address: yup.string().nullable(),
  resignedOn: yup
    .string()
    .required()
    .dateFormat()
    .label('退職日'),
  lastSalaryOn: yup
    .string()
    .dateFormat()
    .label('最後の給与支給日'),
  withholdingSlipStatus: yup
    .mixed<WithholdingSlipStatusType>()
    .oneOf(['submitted', 'not_submitted', 'unavailable', 'in_order'])
    .required()
    .label('源泉徴収票')
    .meta({ select: true }),
  withholdingSlipImage: yup.mixed<File>().nullable(),
  paymentAmount: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('支払金額'),
  deductionAmount: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('社会保険料等の金額'),
  taxAmount: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('源泉徴収税額')
});

export const schema = yup.object({
  formerJobs: yup.array().of(formerJobSchema)
});

export const generateDefaultValues = (formerJobs: FormerJob[] | undefined) => {
  if (!formerJobs || formerJobs.length === 0) {
    return { formerJobs: [generateFormerJob()] };
  }

  return { formerJobs: formerJobs.map(formerJob => generateFormerJob(formerJob)) };
};

export const generateFormerJob = (formerJob?: FormerJob): FormerJobSchema => {
  const generateFileTypeObj = (image: FileType | undefined) => {
    if (!image) return null;
    if (!image.url && !image.filename && !image.file) return null;

    return image;
  };

  return {
    id: formerJob?.id ? String(formerJob?.id) : undefined,
    companyName: formerJob?.companyName || '',
    address: formerJob?.address,
    resignedOn: dateFormFormat(formerJob?.resignedOn, 'L'),
    lastSalaryOn: dateFormFormat(formerJob?.lastSalaryOn, 'L'),
    withholdingSlipStatus: formerJob?.withholdingSlipStatus || null,
    withholdingSlipImage: generateFileTypeObj(formerJob?.withholdingSlipImage),
    paymentAmount: amountFormat(formerJob?.paymentAmount),
    deductionAmount: amountFormat(formerJob?.deductionAmount),
    taxAmount: amountFormat(formerJob?.taxAmount)
  };
};

export const emptyFormerJob: FormerJobSchema = {
  id: undefined,
  companyName: '',
  address: '',
  resignedOn: dateFormFormat(''),
  lastSalaryOn: dateFormFormat(''),
  withholdingSlipStatus: null,
  withholdingSlipImage: null,
  paymentAmount: '',
  deductionAmount: '',
  taxAmount: ''
};
