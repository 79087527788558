import { yup } from '@jbc-year-end-adj/common/yup';

export type Schema = {
  autoSummary: string;
  summary: string;
  remark: string;
};

export const schema = yup.object({
  autoSummary: yup.string().nullable(),
  summary: yup.string().nullable(),
  remark: yup.string().nullable()
});
