import { FC } from 'react';
import useReactRouter from 'use-react-router';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';

import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { WITHHOLDING_SLIP_SUMMARY, QueryResult } from './query';
import { Form } from './parts/Form';
import styles from './WithholdingSlipSummary.scss';

export const WithholdingSlipSummary: FC = () => {
  const {
    match: {
      params: { id }
    }
  } = useReactRouter();

  const { data, loading } = useQuery<QueryResult>(WITHHOLDING_SLIP_SUMMARY, { variables: { id }, fetchPolicy: 'network-only' });

  if (loading) return <Loading />;

  const withholdingSlip = data?.client?.clientYearly?.employee?.withholdingSlip;
  if (!data || !withholdingSlip) throw Error;
  const formerJobs = data?.client?.clientYearly?.employee?.profile?.formerJobs;

  return (
    <div className={styles.container}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">源泉徴収票</h1>
      </div>
      <Form employeeId={id} formerJobs={formerJobs} withholdingSlip={withholdingSlip} />
    </div>
  );
};
