// @ts-ignore
import { parse } from 'query-string';

export const makeBackUrl = ({ pathname, search }: { pathname: string; search: string }) => {
  return encodeURIComponent(pathname + search);
};

export const toBlob = (base64: string, mimeCtype: string) => {
  const bin = atob(base64);
  const buffer = new Uint8Array(bin.length).map((_, i) => bin.charCodeAt(i));
  return new Blob([buffer.buffer], { type: mimeCtype });
};

export const parseBackUrl = (location: Location, fallback: string) => {
  const backTo = parse(location.search)?.back_to as string;
  if (!!backTo && backTo.match(/^\/.*/)) {
    return backTo;
  } else {
    return fallback;
  }
};
