import { FC } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useReactRouter from 'use-react-router';
// @ts-ignore
import { Link } from 'react-router-dom';

import { Upload } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';

import { year } from '@jbc-year-end-adj/2024/consts';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { useAsyncTask } from '@jbc-year-end-adj/2024/features/AsyncTask';
import { FileField } from '@jbc-year-end-adj/2024/components/react-hook-form/FileField';
import { FileImportDescription } from './parts/FileImportDescription';
import { IMPORT } from './mutation';
import { schema, Schema } from './schema';
import styles from './ImportWithholdingSlips.scss';

export const fileEncodings = [
  { value: 'utf8', label: 'UTF-8(推奨)' },
  { value: 'sjis', label: 'Shift-JIS' }
];

export const ImportWithholdingSlips: FC = () => {
  const [importWithholdingSlipsCsv] = useMutation(IMPORT);

  const notify = useNotify();
  const { history } = useReactRouter();
  const { taskRunningProps } = useAsyncTask();

  const methods = useForm<Schema>({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, watch, setValue } = methods;

  const file = watch('file');

  const onSubmit: SubmitHandler<Schema> = async data => {
    if (!data.file) {
      notify('ファイルを選択してください', 'error');
      return;
    }

    await importWithholdingSlipsCsv({ variables: { input: { file: data.file } } });
    history.push(`/${year}/result`);
  };

  return (
    <div>
      <div className="l-contents-wrap">
        <h1 className="m-title-main">給与・賞与の一括登録</h1>
        <p className="m-title-main-note">
          従業員の給与・賞与金額を一括で登録できます。
          <br />
          各従業員の源泉徴収票編集画面から完了してください。
          <br />※ ジョブカン給与計算から情報を取得する場合には
          <Link to={`/${year}/client_setting`} className="u-txt-link">
            こちら
          </Link>
        </p>

        <div className={styles.wrap}>
          <div className={styles.head}>ファイルを選択してください</div>
          <div className={styles.body}>
            <FormProvider {...methods}>
              <p className={styles.text}>
                対応しているファイル形式はCSVとなります。
                <br />
                給与・賞与データを
                <Link to={`/${year}/result`} className="u-txt-link">
                  こちら
                </Link>
                からダウンロードし、変更後のファイルをアップロードして下さい。
              </p>

              <FileField.FileContainer>
                <FileField name="file" />
                <FileField.File file={file} onRemove={() => setValue('file', undefined)} />
              </FileField.FileContainer>

              <Button icon={<Upload size={16} />} primary className={styles.large} {...taskRunningProps} onClick={handleSubmit(onSubmit)}>
                アップロード
              </Button>
            </FormProvider>
          </div>
        </div>

        <FileImportDescription />
      </div>
    </div>
  );
};
