import { useState, useEffect, useCallback } from 'react';
import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import { logError } from '@jbc-year-end-adj/common/telemetry';

export type Address = {
  id: number;
  postcode: number;
  prefecture: string;
  prefectureKana: string;
  city: string;
  cityKana: string;
  street: string;
  streetKana: string;
  cityRcd: {
    id: number;
    code: string;
    name: string;
  };
};

type QueryResult = {
  postcode: Address;
};

type QueryVariables = {
  postcode: number;
  year: number;
};

type EmployeeQueryVariables = {
  postcode: number;
};

const FETCH_POSTCODE = gql`
  query postcode($postcode: Int!, $year: Int!) {
    postcode(postcode: $postcode, year: $year) {
      id
      city
      prefecture
      street
      streetKana
      cityKana
      postcode
      cityRcd {
        id
        code
        name
      }
    }
  }
`;

const FETCH_POSTCODE_EMPLOYEE = gql`
  query postcode($postcode: Int!) {
    postcode(postcode: $postcode) {
      id
      city
      prefecture
      street
      streetKana
      cityKana
      postcode
      cityRcd {
        id
        code
        name
      }
    }
  }
`;

const validatedPostcode = (postcode: string): number | null => {
  if (!postcode.match(/^\d{7}$/)) return null;
  return Number(postcode);
};

/** @deprecated 2023年度従業員アンケート用のHook。もう使うことはない。 */
export const useEmployeeAddress = () => {
  const [address, setAddress] = useState<Address>();
  const [fetch, { data, loading }] = useLazyQuery<QueryResult, EmployeeQueryVariables>(FETCH_POSTCODE_EMPLOYEE, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!loading && data) {
      setAddress(data.postcode);
    }
  }, [loading]);

  return { fetchAddress: fetch, address };
};

type FetchAddress = (postcode: string, year: number) => Promise<Address | null>;

export const useFetchAddress = (): FetchAddress => {
  const client = useApolloClient();

  return useCallback(
    async (postcode: string, year: number) => {
      const pc = validatedPostcode(postcode);
      if (!pc) return null;

      const result = await client.query<QueryResult, QueryVariables>({
        query: FETCH_POSTCODE,
        variables: { postcode: pc, year },
        fetchPolicy: 'network-only'
      });
      return result.data?.postcode;
    },
    [client]
  );
};

export const suppressFetchAddressError = (error: Error) => {
  logError(error);
  if (process.env.NODE_ENV !== 'production') {
    console.error(error);
  }
  return null;
};

export const useAddressImmediate = (postcode: string, year: number) => {
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState<Address | null>(null);
  const fetchAddress = useFetchAddress();

  useEffect(() => {
    setLoading(true);

    let cancelled = false;
    fetchAddress(postcode, year)
      .catch(suppressFetchAddressError)
      .then(address => {
        if (cancelled) return;
        setLoading(false);
        setAddress(address);
      });
    return () => {
      cancelled = true;
    };
  }, [postcode, year, fetchAddress]);

  return { loading, address };
};
